import {
  differenceInSeconds,
  format,
  isBefore,
  isSameDay,
  isValid,
  parse,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
  startOfSecond,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import dayjs from 'dayjs'

import { DATE_FORMAT } from 'common/constants/dateFormatConstants'
import type { DATE_FORMAT_TYPE } from 'common/types/dateFormatType'

// Type guard function for date format 'YYYY-MM-DD'
const isDateFormatValid = (value: string): value is string => {
  const regex = /^\d{4}-\d{2}-\d{2}$/
  return regex.test(value)
}

const formatDate = (date: string, type: DATE_FORMAT_TYPE): string => {
  if (!isDateFormatValid(date))
    throw new Error("Invalid date format. Expected format: 'YYYY-MM-DD'")

  return format(new Date(date), type)
}

const formatDisplayDate = (
  init_date: string | null,
  includeTime = false,
  letterMonth = false,
  includeDay = false,
): string => {
  const date = dayjs(init_date)

  if (!date.isValid()) {
    return ''
  }

  const baseFormat = letterMonth ? 'MMM D, YYYY' : 'MM/DD/YYYY'

  const dayFormat = includeDay ? 'ddd, ' : ''
  const timeFormat = includeTime ? ' hh:mm A' : ''

  const format = `${dayFormat}${baseFormat}${timeFormat}`

  return date.format(format)
}

const getDateByTime = (hour: string) => {
  const [hours, minutes] = hour.split(':')
  const date = format(new Date(), DATE_FORMAT.CALENDAR_DATE)

  const newDate = setSeconds(
    setMinutes(setHours(parseISO(date), parseInt(hours)), parseInt(minutes)),
    0,
  )

  return newDate
}

const getHourFromNumbers = ({ hour, minute }: { hour: number; minute: number }) => {
  const hourString = hour < 10 ? `0${hour}` : `${hour}`
  const minuteString = minute < 10 ? `0${minute}` : `${minute}`

  return `${hourString}:${minuteString}`
}

const parseExpirationDate = (month: number, year: number): string => {
  // Todo fix show component
  if (!month || !year) return ''
  const date = `${month}/${year}`
  const inputDate = parse(date, DATE_FORMAT.DEFAULT_EXPIRY_DATE_FORMAT, new Date())

  return format(inputDate, DATE_FORMAT.EXPIRY_DATE_FORMAT)
}

const isPast = (date: Date, timeZone: string) => {
  const currentDate = startOfSecond(new Date())
  const timeZoneDate = startOfSecond(utcToZonedTime(currentDate, timeZone))
  const inputDate = startOfSecond(date)

  const difference = differenceInSeconds(timeZoneDate, inputDate)

  return difference > 59
}
const getIsValidAndCurrentDay = (date: string, timeZone: string): boolean => {
  if (!isValid(new Date(date))) return false

  const clientCurrentDateToServerZone = utcToZonedTime(new Date(), timeZone)
  const selectedDateNoTransform = new Date(`${date} 00:00`)

  return isSameDay(clientCurrentDateToServerZone, selectedDateNoTransform)
}

const getIsDateBeforeCurrentDate = (date: string, timeZone: string) => {
  const clientCurrentDateToServerZone = utcToZonedTime(new Date(), timeZone)
  const selectedDateNoTransform = new Date(`${date} 00:00`)

  return isBefore(selectedDateNoTransform, clientCurrentDateToServerZone)
}

const getNowToServerZone = (timeZone: string, time?: Date) => {
  const clientCurrentDateToServerZone = utcToZonedTime(time || new Date(), timeZone)

  return clientCurrentDateToServerZone
}

const dateRangeOverlaps = (a_start: Date, a_end: Date, b_start: Date, b_end: Date): boolean => {
  if (a_start <= b_start && b_start <= a_end) return true
  if (a_start <= b_end && b_end <= a_end) return true
  if (b_start < a_start && a_end < b_end) return true
  return false
}

const formatDateTitles = (date: string, hourAsString: string): string => {
  // client wants to display Mon, Tu, Wed, Th, Fri....
  let formattedHour = hourAsString.trim().replace(' ', '')
  const shortDays = ['Tue', 'Thu']
  let day = dayjs(date).format('ddd')
  if (shortDays.includes(day)) {
    day = day.slice(0, -1)
  }
  return `${day}${dayjs(date).format(", MMM D, 'YY")} ${formattedHour}`
}

export const DateService = {
  formatDate,
  getDateByTime,
  parseExpirationDate,
  getHourFromNumbers,
  isPast,
  getIsValidAndCurrentDay,
  getIsDateBeforeCurrentDate,
  getNowToServerZone,
  dateRangeOverlaps,
  formatDateTitles,
  formatDisplayDate,
}
