import { Col, Row } from 'antd'
import dayjs from 'dayjs'

import { ReactComponent as RemoveIcon } from 'features/Cart/assets/trash.svg'

type RoomDetailsType = {
  id: number
  name: string
  timeSlot: string
  date: string
  status?: string
}

type Props = {
  room: RoomDetailsType
  isDeleteDisabled?: boolean
  hasError?: boolean
  handleDeleteBooking: () => void
}

const SlotDetails = ({
  room,
  isDeleteDisabled = false,
  hasError = false,
  handleDeleteBooking,
}: Props) => {
  const isDateFromTomorrow = dayjs(room.date).isSameOrAfter(dayjs().add(1, 'days'), 'day')

  return (
    <Row className='slot-container' data-error={hasError}>
      <Col xs={24}>
        <Row justify='space-between' align='middle'>
          <Col>
            <span className='room-label'>{room.name}</span>
          </Col>
          {isDateFromTomorrow && (
            <Col>
              <button
                disabled={isDeleteDisabled}
                className='remove-button'
                onClick={handleDeleteBooking}>
                <RemoveIcon />
              </button>
            </Col>
          )}
        </Row>
        <Row>
          <span className='room-date'>{room.timeSlot}</span>
        </Row>
      </Col>
    </Row>
  )
}

export default SlotDetails

export const SlotDetailsList = ({
  rooms,
  isDeleteDisabled = false,
  handleDeleteBooking,
}: {
  rooms: RoomDetailsType[]
  isDeleteDisabled?: boolean
  handleDeleteBooking: (hasRoomError: boolean, bookingId: number) => void
}) => {
  return (
    <div className='slot-details-list'>
      {rooms.map((room) => {
        const hasRoomError = room?.status === 'error'
        return (
          <SlotDetails
            isDeleteDisabled={isDeleteDisabled}
            key={room.id}
            room={room}
            hasError={hasRoomError}
            handleDeleteBooking={() => handleDeleteBooking(hasRoomError, room.id)}
          />
        )
      })}
    </div>
  )
}
