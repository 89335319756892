import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import type { TablePaginationConfig } from 'antd'

import { Table } from 'common/components/Table/Table'
import { TagStatus } from 'common/components/TagStatus/TagStatus'
import { DEFAULT_TABLE_PARAMS } from 'common/constants/defaultTableParamsConstants'
import { UtilService } from 'common/services/utilService'
import type { TAG_STATUS } from 'common/types/tagStatusType'

import type { IBooking } from 'features/Booking'
import { useLazyFetchBookingsWithPaginationQuery } from 'features/Booking/state/api/bookingApi'
import { PathUtils } from 'routes/routes.utils'

const CompletedBookings = () => {
  const { siteId } = useParams()
  const [fetchCompletedLeases, { data, isFetching }] = useLazyFetchBookingsWithPaginationQuery()

  const [tableParams, setTableParams] = useState<TablePaginationConfig>({
    ...DEFAULT_TABLE_PARAMS,
  })

  useEffect(() => {
    if (data?.total_items >= 0) setTableParams({ ...tableParams, total: data.total_items })
  }, [data])

  useEffect(() => {
    if (siteId) {
      fetchCompletedLeases({ type: 'completed', siteId, tableParams })
    }
  }, [siteId, tableParams.pageSize, tableParams.current])

  const getLeasePath = (leaseId: string, isPromotion: boolean) => {
    if (isPromotion) {
      return PathUtils.getPromotionDetails(siteId, leaseId)
    }
    return PathUtils.getBookingDetails(siteId, leaseId)
  }

  return (
    <>
      <Table
        itemKey='id'
        haveActions={false}
        dataSource={data?.items}
        columns={columns({ getLeasePath })}
        isLoading={isFetching}
        tableParams={tableParams}
        setTableParams={setTableParams}
      />
    </>
  )
}

export default CompletedBookings

const columns = ({
  getLeasePath,
}: {
  getLeasePath: (leaseId: string, isPromotion: boolean) => string
}) => [
  {
    title: 'Booking number',
    dataIndex: 'id',
    key: 'id',
    width: 130,
    sorter: (a: IBooking, b: IBooking) => a.id && b.id && a.id.localeCompare(b.id),
    render: (id: string, item: IBooking) => {
      return (
        <Link className='customLink' to={getLeasePath(id, item.is_promotion)}>
          {id}
        </Link>
      )
    },
  },
  {
    title: 'Booked',
    dataIndex: 'lease_type_name',
    key: 'lease_type_name',
    sorter: (a: IBooking, b: IBooking) => {
      const nameA = a.is_promotion ? 'PROMOTION' : a.lease_type_name.toUpperCase()
      const nameB = b.is_promotion ? 'PROMOTION' : b.lease_type_name.toUpperCase()
      return nameA.localeCompare(nameB)
    },
    render: (lease_type_name: string, item: IBooking) => {
      const name = item.is_promotion ? 'Promotion' : lease_type_name
      return name.toUpperCase()
    },
  },
  {
    title: 'Started',
    dataIndex: 'start_date',
    key: 'start_date',
    sorter: (a: IBooking, b: IBooking) =>
      new Date(a.start_date).valueOf() - new Date(b.start_date).valueOf(),
  },
  {
    title: 'Ended',
    dataIndex: 'end_date',
    key: 'end_date',
    sorter: (a: IBooking, b: IBooking) =>
      new Date(a.end_date).valueOf() - new Date(b.end_date).valueOf(),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: (a: IBooking, b: IBooking) => {
      const statusA = UtilService.capitalizeFirstLetter(a.status)
      const statusB = UtilService.capitalizeFirstLetter(b.status)
      return statusA.localeCompare(statusB)
    },
    render: (status: TAG_STATUS) => (
      <TagStatus status={UtilService.capitalizeFirstLetter(status) as TAG_STATUS} />
    ),
  },
]
