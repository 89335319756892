import './payment-history.scss'

import { type FC, memo, useEffect } from 'react'

import { Table } from 'common/components/Table/Table'
import type { ITriggerPagRequest } from 'common/interfaces/IRequestResponse'

import { SETTINGS_CONSTANTS } from 'features/Profile/features/Payment/constants/constants'
import type { ISettingHistory } from 'features/SettingsOld/interfaces/ISettingHistory'
import { useLazyPaymentHistoryQuery } from 'features/SettingsOld/state/api/settingsApi'

import { paymentHistoryColumns } from './payment-history.columns'
import { useSites } from 'common/hooks/useSites'

export const PaymentHistory: FC = memo(() => {
  const { selectedSite } = useSites()

  const [
    fetchPaymentHistory,
    { data: paymentHistory, isFetching: isLoadingSiteHistory },
  ]: ITriggerPagRequest<ISettingHistory[]> = useLazyPaymentHistoryQuery()

  useEffect(() => {
    if (!selectedSite) {
      return
    }
    fetchPaymentHistory({ site: selectedSite.code })
  }, [selectedSite])

  return (
    <div className='payment-history'>
      <h1 className='title'>{SETTINGS_CONSTANTS.PAYMENTS_HISTORY}</h1>
      <Table
        itemKey='id'
        haveActions={false}
        dataSource={paymentHistory}
        columns={paymentHistoryColumns}
        isLoading={isLoadingSiteHistory}
      />
    </div>
  )
})
