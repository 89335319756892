import type { FC } from 'react'
import { memo, useEffect, useMemo, useState } from 'react'

import { Skeleton, Spin } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'

import playlist from 'features/Home/assets/Playlist.svg'

import { ArrayElements } from 'common/components/ArrayElements/ArrayElements'
import { SuffixSelect } from 'common/components/Select'
import { Show } from 'common/components/Show/Show'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { ApiResponse, ITriggerRequest } from 'common/interfaces/IRequestResponse'
import { UtilService } from 'common/services/utilService'
import { getMultipleSuffix, upperCaseFirstLetterOfEachWord } from 'common/utils/text.utils'
import { AccessCodes } from 'features/Home/Book/Booking/BookingDetails/GoodToKnow/AccessCodes/AccessCodes'
import { GoodToKnowSection } from 'features/Home/Book/Booking/BookingDetails/GoodToKnow/GoodToKnowSection/GoodToKnowSection'
import { SectionItem } from 'features/Home/Book/Booking/BookingDetails/GoodToKnow/SectionItem/SectionItem'
import { INFO_CONSTANTS } from 'features/Home/constants/infoConstants'
import { INFO_GOOD_TO_KNOW_CONSTANTS } from 'features/Home/constants/infoGoodToKnow'
import type { IAccessCode } from 'features/Home/interfaces/IInfoGoodToKnow'
import { useLazyResendCodeQuery, useLazyResetCodeQuery } from 'features/Home/Book/state/api/bookApi'

import styles from './goodToKnow.module.scss'
import type { GoodToKnowProps } from './goodToKnow.types'
import { useBooking } from 'common/hooks/useBooking'
import { useParams } from 'react-router-dom'
import { EhrDetails } from './EhrDetails/EhrDetails'

export const GoodToKnow: FC<GoodToKnowProps> = memo(
  ({ booking, isBookingActive, isFetching, withDeposit, fullWidth, handleUpdateAmbientMusic }) => {
    const [accessCodes, setAccessCode] = useState<IAccessCode[]>([])

    const { bookingId, siteId } = useParams()
    const { processApiResponse } = useApiResponse()
    const { isStorage, isRoom, isRecurring, isMailbox } = useBooking(booking)

    const [resetCode, { isFetching: isResetCode }]: ITriggerRequest = useLazyResetCodeQuery()
    const [resendCode, { isFetching: isResendCode }]: ITriggerRequest = useLazyResendCodeQuery()

    const isPromotion = booking?.isPromotion
    const {
      access_codes,
      wifi_access,
      ambient_music,
      site_details,
      cleaning_deposit,
      details_ehr,
    } = booking ?? {}
    const { address, city, state, zip_code } = site_details ?? {}

    const siteLocation = useMemo(() => {
      if (!address || !city || !state || !zip_code) return undefined

      return [address, `${city}, ${state?.slice(0, 2)}, ${zip_code}`]
    }, [address, city, state, zip_code])

    function filterAccessCodes(codes: IAccessCode[]): IAccessCode[] {
      return codes.filter((access) => !!access.code)
    }

    useEffect(() => {
      if (access_codes) setAccessCode(filterAccessCodes(access_codes))
    }, [access_codes])

    const handleReset = async (id: number): Promise<void> => {
      const response: ApiResponse = await resetCode({
        id,
        bookingId,
        siteId,
      })
      processApiResponse(response, {
        error: 'Reset code failed',
        success: 'Reset code success',
        successCallback: () => {
          setAccessCode(filterAccessCodes(response.data.access_codes))
        },
      })
    }
    const handleResend = async (id: number): Promise<void> => {
      const response: ApiResponse = await resendCode({
        id,
        bookingId,
        siteId,
      })
      processApiResponse(response, {
        error: 'Resend code failed',
        success: 'Resend code success',
        successCallback: () => {
          setAccessCode(filterAccessCodes(response.data.access_codes))
        },
      })
    }

    return (
      <Spin spinning={isResetCode || isResendCode}>
        <div
          className={classNames(styles.parent, {
            [styles.parentLoad]: isResetCode || isResendCode,
            [styles.parentFullWidth]: fullWidth,
          })}>
          <h3 className={styles.parentTitle}>{INFO_GOOD_TO_KNOW_CONSTANTS.GOOD_TO_KNOW}</h3>
          <Show when={isFetching}>
            <div className={styles.parentLoading}>
              <ArrayElements total={3}>
                <Skeleton paragraph={{ rows: 2 }} active />
              </ArrayElements>
            </div>
          </Show>

          <Show when={booking && !isFetching}>
            <div className={styles.parentContent}>
              <Show when={!!details_ehr?.length}>
                <GoodToKnowSection title={INFO_GOOD_TO_KNOW_CONSTANTS.EHR_DETAILS}>
                  <EhrDetails details={details_ehr} />
                </GoodToKnowSection>
              </Show>

              <Show when={!isStorage && accessCodes?.length}>
                <GoodToKnowSection title={INFO_GOOD_TO_KNOW_CONSTANTS.ACCESS_CODES}>
                  <AccessCodes
                    codes={accessCodes}
                    isDoctor={withDeposit}
                    resetCode={handleReset}
                    resendCode={handleResend}
                    isBookingActive={isBookingActive}
                  />
                </GoodToKnowSection>
              </Show>

              <Show when={isStorage || isRecurring || isPromotion}>
                <GoodToKnowSection
                  title={
                    isPromotion
                      ? INFO_GOOD_TO_KNOW_CONSTANTS.CONTRACT_PERIOD
                      : INFO_GOOD_TO_KNOW_CONSTANTS.LEASE_PERIOD
                  }>
                  <div style={{ display: 'flex' }}>
                    <SectionItem
                      field={`${
                        booking?.start_date ? dayjs(booking?.start_date).format('MMMM D, YYYY') : ''
                      } -> ${
                        booking?.end_date ? dayjs(booking?.end_date).format('MMMM D, YYYY') : ''
                      }`}
                    />
                  </div>
                </GoodToKnowSection>

                <Show when={!isRecurring && !isPromotion}>
                  <GoodToKnowSection title={INFO_GOOD_TO_KNOW_CONSTANTS.ITEMS}>
                    <SectionItem
                      field={INFO_GOOD_TO_KNOW_CONSTANTS.EQUIPMENT_STATUS}
                      value={booking.items?.equipment_status}
                    />
                  </GoodToKnowSection>
                </Show>
              </Show>

              <Show when={isPromotion}>
                <GoodToKnowSection title={INFO_GOOD_TO_KNOW_CONSTANTS.CONVENTION}>
                  {booking.convention?.rooms.map((room) => {
                    const hasWeekdaySlots = room.weekday_slots > 0
                    const hasWeekendSlots = room.weekend_slots > 0

                    return (
                      <SectionItem
                        key={room.name}
                        field={room.name}
                        className='items-start'
                        value={
                          <div style={{ textAlign: 'right' }}>
                            {hasWeekdaySlots &&
                              `Any ${room.weekday_slots} ${getMultipleSuffix(
                                room.weekday_slots,
                                'slot',
                              )} from Mon to Fri \n(${room.slot_period})`}
                            {hasWeekdaySlots && hasWeekendSlots && <br />}
                            {hasWeekendSlots &&
                              `Any ${room.weekend_slots} ${getMultipleSuffix(
                                room.weekend_slots,
                                'slot',
                              )} from Sat to Sun \n(${room.slot_period})`}
                          </div>
                        }
                      />
                    )
                  })}
                  {booking?.convention?.options?.map(({ name }) => {
                    return <SectionItem key={name} field={name} />
                  })}
                </GoodToKnowSection>
              </Show>

              <GoodToKnowSection title={INFO_GOOD_TO_KNOW_CONSTANTS.WIFI_ACCESS}>
                <SectionItem
                  value={wifi_access?.name}
                  field={INFO_GOOD_TO_KNOW_CONSTANTS.WIFI_NAME}
                />
                <SectionItem
                  type='secret'
                  value={wifi_access?.password}
                  field={INFO_GOOD_TO_KNOW_CONSTANTS.PASSWORD}
                />
              </GoodToKnowSection>

              <Show when={isRecurring || isMailbox}>
                <GoodToKnowSection title={INFO_GOOD_TO_KNOW_CONSTANTS.BOOKED}>
                  <SectionItem
                    value={booking?.hardware_identifier || booking?.box_number}
                    field={upperCaseFirstLetterOfEachWord(booking?.lease_type_name) ?? 'N/A'}
                  />
                </GoodToKnowSection>
              </Show>

              <GoodToKnowSection title={INFO_GOOD_TO_KNOW_CONSTANTS.NEED_HELP}>
                <SectionItem
                  value={site_details?.phone}
                  field={INFO_GOOD_TO_KNOW_CONSTANTS.SITE_MANAGER}
                />
              </GoodToKnowSection>

              <GoodToKnowSection title={INFO_GOOD_TO_KNOW_CONSTANTS.SITE_LOCATION}>
                <SectionItem
                  type='map'
                  value={siteLocation?.join(' ')}
                  field={
                    <div>
                      {siteLocation &&
                        siteLocation.map((line, index) => <div key={index}>{line}</div>)}
                    </div>
                  }
                />
              </GoodToKnowSection>

              <Show when={withDeposit}>
                <GoodToKnowSection title={INFO_GOOD_TO_KNOW_CONSTANTS.CLEANING_DEPOSIT}>
                  <SectionItem
                    field={INFO_GOOD_TO_KNOW_CONSTANTS.VALUE}
                    value={UtilService.numberToDollar(cleaning_deposit?.value)}
                  />
                  <Show when={cleaning_deposit?.refunded}>
                    <SectionItem
                      type='info'
                      field={INFO_GOOD_TO_KNOW_CONSTANTS.REFUNDED}
                      value={UtilService.numberToDollar(cleaning_deposit?.refunded)}
                    />
                    <SectionItem
                      type='info'
                      value={cleaning_deposit?.refunded_date}
                      field={INFO_GOOD_TO_KNOW_CONSTANTS.REFUNDED_DATE}
                    />
                  </Show>
                </GoodToKnowSection>
              </Show>
            </div>
          </Show>
          <Show when={isRoom || isPromotion}>
            <div className={styles.parentAmbient}>
              <h3 className={styles.parentTitle}>{INFO_GOOD_TO_KNOW_CONSTANTS.AMBIENT_MUSIC}</h3>
              <p className={styles.parentFooterDescription}>
                {INFO_GOOD_TO_KNOW_CONSTANTS.SELECT_PLAYLIST}
              </p>
              <SuffixSelect
                icon={playlist}
                isLoading={isFetching}
                disabled={!isBookingActive && !isPromotion}
                onChange={(musicId: number) => {
                  if (musicId === -1) {
                    handleUpdateAmbientMusic(null)
                  } else {
                    handleUpdateAmbientMusic(musicId)
                  }
                }}
                title={INFO_GOOD_TO_KNOW_CONSTANTS.PLAYING}
                defaultValue={ambient_music?.ambient_music_id || INFO_CONSTANTS.NO_MUSIC_OPTION}
                listOptions={[
                  { code: -1, name: INFO_CONSTANTS.NO_MUSIC_OPTION },
                  ...(ambient_music?.ambient_music_list || []),
                ]}
              />
            </div>
          </Show>
        </div>
      </Spin>
    )
  },
)
