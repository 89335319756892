import { useEffect, useMemo, useState } from 'react'
import { useToggle } from 'react-use'

import type { TablePaginationConfig, UploadFile } from 'antd'
import type { FormikValues } from 'formik'

import { Button } from 'common/components/Button/Button'
import { Modal } from 'common/components/Modal'
import { Table } from 'common/components/Table/Table'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'
import { DEFAULT_TABLE_PARAMS } from 'common/constants/defaultTableParamsConstants'
import { MODAL_LABELS } from 'common/constants/modalConstants'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { IOption } from 'common/interfaces/IOption'
import type {
  ApiResponse,
  ITriggerPagRequest,
  ITriggerRequest,
  ITriggerResponse,
} from 'common/interfaces/IRequestResponse'
import type { IUploadedFileSrc } from 'common/interfaces/IUploadedFileSrc'

import { SupportForm } from 'features/Support/components/SupportForm/SupportForm'
import { SUPPORT_CONSTANTS } from 'features/Support/constants/supportConstants'
import { SUPPORT_TABLE_COLUMNS } from 'features/Support/constants/supportTableColumns'
import type { ISupportTicket, ITicket } from 'features/Support/interfaces/ISupport'
import {
  useCreateTicketMutation,
  useLazyFetchTicketCategoriesQuery,
  useLazyFetchTicketsQuery,
} from 'features/Support/state/api/supportApi'

import styles from './supportContent.module.scss'
import { useParams } from 'react-router-dom'

export const SupportContent = () => {
  const [isOpenTicketModel, toggleIsOpenTicketModal] = useToggle(false)

  const { processApiResponse } = useApiResponse()
  const { siteId } = useParams()

  const [tableParams, setTableParams] = useState<TablePaginationConfig>({
    ...DEFAULT_TABLE_PARAMS,
  })

  const [createTicket, { isLoading: isCreatingTicket }]: ITriggerRequest<ITriggerResponse> =
    useCreateTicketMutation()

  const [fetchTickets, { isFetching: isFetchTickets, data }]: ITriggerPagRequest<ITicket[]> =
    useLazyFetchTicketsQuery()

  const [fetchCategories, { data: categoriesData }]: ITriggerRequest<IOption[]> =
    useLazyFetchTicketCategoriesQuery()

  const handleSubmitTicket = async (value: FormikValues, resetForm: () => void): Promise<void> => {
    const adjustedAttachments: IUploadedFileSrc[] = value.attachments.map(
      ({ response }: UploadFile): IUploadedFileSrc => ({
        path: response?.file?.path,
        name: response?.file?.name,
      }),
    )
    const category_code: number = categoriesData.find(
      (category: IOption): boolean => category.name === value.category,
    )?.code as number

    const ticket: ISupportTicket = {
      category_code,
      subject: value.subject,
      message: value.message,
      attachments: adjustedAttachments,
    }
    const response: ApiResponse = await createTicket({ ticket, siteId })
    processApiResponse(response, {
      success: SUPPORT_CONSTANTS.ALERT_TICKET_SUBMITTED,
      successCallback: (): void => {
        toggleIsOpenTicketModal()
        fetchTickets({ siteId, tableParams })
        resetForm()
      },
    })
  }

  const adjustedCategories: string[] = useMemo(
    (): string[] =>
      Array.isArray(categoriesData)
        ? categoriesData.map((category: object): string => category['name' as keyof object])
        : [],
    [categoriesData],
  )

  useEffect(() => {
    if (!siteId) {
      return
    }

    fetchTickets({ siteId, tableParams })
  }, [siteId, tableParams.pageSize, tableParams.current])

  useEffect(() => {
    if (data?.total_items) {
      setTableParams({ ...tableParams, total: data.total_items })
    }
  }, [data])

  useEffect(() => {
    if (isOpenTicketModel) {
      fetchCategories()
    }
  }, [isOpenTicketModel])

  return (
    <div className={styles.parent}>
      <Modal cancelButton={false} open={isOpenTicketModel} title={MODAL_LABELS.SUBMIT_TICKET}>
        <SupportForm
          adjustedCategories={adjustedCategories}
          handleSubmitTicket={handleSubmitTicket}
          toggleIsOpenTicketModal={toggleIsOpenTicketModal}
        />
      </Modal>
      <div className={styles.parentHead}>
        <h1>{SUPPORT_CONSTANTS.MY_TICKETS}</h1>
        <Button onClick={toggleIsOpenTicketModal}>{BUTTON_CONSTANTS.NEW_TICKET}</Button>
      </div>

      <Table
        itemKey='ticket_id'
        haveActions={false}
        dataSource={data?.items}
        tableParams={tableParams}
        setTableParams={setTableParams}
        columns={SUPPORT_TABLE_COLUMNS}
        isLoading={isFetchTickets || isCreatingTicket}
      />
    </div>
  )
}
